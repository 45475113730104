<template>
  <Nuxt @touchstart.native.once="preloadAudioContext" />
</template>

<script>
import { preloadAudioContext } from '~/utils/useAudio'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    return {
      preloadAudioContext,
    }
  },
})
</script>
