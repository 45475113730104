import { onMounted } from '@nuxtjs/composition-api'
import once from 'lodash.once'

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext
  }
}

const getAudioContext = once(() => {
  const AudioContext = window.AudioContext || window.webkitAudioContext
  const context = new AudioContext()
  const gainNode = context.createGain()
  gainNode.gain.value = 1
  return context
})

export const preloadAudioContext = once(() => {
  const context = getAudioContext()
  const source = context.createBufferSource()
  source.buffer = context.createBuffer(1, 1, 22050)
  source.connect(context.destination)
  source.start()
})

export const useAudio = (path: string) => {
  let audioBuffer: AudioBuffer | undefined

  onMounted(async () => {
    const context = getAudioContext()
    const arrayBuffer = await window
      .fetch(path)
      .then((res) => res.arrayBuffer())

    // The Promise-based syntax for BaseAudioContext.decodeAudioData() is not supported in Safari
    audioBuffer = await new Promise<AudioBuffer>((resolve, reject) => {
      context.decodeAudioData(arrayBuffer, resolve, reject)
    })
  })

  const play = () => {
    if (!audioBuffer) return
    const context = getAudioContext()
    const source = context.createBufferSource()
    source.buffer = audioBuffer
    source.connect(context.destination)
    source.start()
  }

  return {
    play,
  }
}
